.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    color: white;
    font-family: 'Inter', sans-serif;

  }
  
  .logo-container svg {
    width: 50px;
    height: 50px;
  }
  
  .nav-buttons button {
    margin-left: 20px;
    padding: 10px 15px;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .nav-buttons button:hover {
    background-color: #ccc;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
    color: black; /* Change text color to white (or any color you prefer) */

  }
  
  .main-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./assets/background.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.2; /* 50% opacity for the background image */
    z-index: -1; /* Make sure the pseudo-element is behind the content */
  }

  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    text-align: center;
    font-family: 'Inter', sans-serif;
  }
  
  .overlay svg {
    width: 100px;
    height: 100px;
    /* margin-bottom: 20px; */
  }
  
  h1 {
    font-size: 4.5rem;
    margin-top:0;
    margin-bottom: 2rem;
    font-family: 'Inter', sans-serif;
  }
  
/* CSS for Footer Component */
.footer {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the left */
    padding: 1rem; /* Add some padding if needed */
    background-color: #f8f9fa; /* Background color for the footer */
  }
  
  .footer p {
    margin-right: 1rem; /* Space between text and social media icons */
  }
  
  .social-media-links {
    display: flex;
    align-items: center;
  }
  
  .social-media-links a {
    margin-right: 0.5rem; /* Space between social media icons */
  }
  
  .social-media-links a:last-child {
    margin-right: 0; /* Remove margin for the last item */
  }
  
  .footer a:hover {
    opacity: 0.7;
  }
  